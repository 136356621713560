import { ISO8601DateIsh, Remms4allForm } from "./types";

function replaceEmptyAndNull(obj: any) {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === "") {
      obj[key] = undefined;
    }
  }
}

export function patchFormData(formData: Remms4allForm) {
  patchDateFields(formData);
  replaceEmptyAndNull(formData);
  patchEnergyData(formData);
}

function patchEnergyData(formData: Remms4allForm) {
  // Replace the dummy ENR_ENTR_NONE HEIZHAUPTENERTRKAT = 10 meaning None
  if (formData["ENR_ENTR_NONE"]) {
    formData["ENR_ENTR_NONE"] = undefined;
    formData["HEIZHAUPTENERTRKAT"] = 10;
  }
}

function patchDateFields(formData: Remms4allForm) {
  if (!/^\d{4}-\d{2}-\d{2}$/.test(formData.realtyDataReferralDate)) {
    formData.realtyDataReferralDate = new Date().toISOString().slice(0, 10) as ISO8601DateIsh;
  }

  [
    //
    formData.DATSTANDIMMOBJAHR,
    formData.DATSTANDIMMOBMON,
    formData.DATSTANDIMMOBTAG,
  ] = splitDate(formData.realtyDataReferralDate);

  [
    //
    formData.DATSTANDENERGJAHR,
    formData.DATSTANDENERGMON,
    formData.DATSTANDENERGTAG,
  ] = splitDate(formData.energyDataReferralDate);

  [
    //
    formData.DATSTANDMARKTWJAHR,
    formData.DATSTANDMARKTWMON,
    formData.DATSTANDMARKTWTAG,
  ] = splitDate(formData.marketValueDataReferralDate);

  [
    //
    formData.GEAK_EA_DAT_J,
    formData.GEAK_EA_DAT_M,
    formData.GEAK_EA_DAT_T,
  ] = splitDate(formData.geakCertifiedAt);

  [
    //
    formData.GEAK_EA_GUEL_J,
    formData.GEAK_EA_GUEL_M,
    formData.GEAK_EA_GUEL_T,
  ] = splitDate(formData.geakCertifiedUntil);

  [
    //
    formData.MINERGIEDATUM_DEKL_J,
    formData.MINERGIEDATUM_DEKL_M,
    formData.MINERGIEDATUM_DEKL_T,
  ] = splitDate(formData.minergieCertifiedAt);
}

/* Split a date string in ISO8601 format into its numeric parts [year, month, day]. */
export function splitDate(dateString: string | undefined) {
  if (!dateString || dateString === "") {
    return [NaN, NaN, NaN];
  }

  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    throw new Error(`Date string ${dateString} is not in ISO8601 format.`);
  }

  return dateString.split("-").map(Number);
}
