// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import _010ErfassungSimpel from "./_010ErfassungSimpel";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import FormWrapper from "./custom/FormWrapper";
import { withAuthenticatedApiService, WithAuthenticatedApiServiceProps } from "../api/AuthenticatedApiService";
import { isSuccessful } from "../api/apiEndpoint";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { RealtyFormType, Remms4allForm } from "../api/types";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { patchFormData } from "../api/formPatch";
import LoadingScreen from "./custom/LoadingScreen";
import _010Erfassung from "./_010Erfassung";

export interface EditRealtiesProps extends WithAuthenticatedApiServiceProps {}

function prepareFormData(formData: Remms4allForm, realty?: Remms4allForm) {
  patchFormData(formData);
  if (realty) {
    formData.R4A_GUI_DET = realty.R4A_GUI_DET;
  } else {
    console.error("realty is undefined");
  }
}

function RealtySnapshotDetail_({ authenticatedApiService, ...props }: EditRealtiesProps, ref: HTMLElementRefOf<"div">) {
  const [realty, setRealty] = React.useState<Remms4allForm | undefined>(undefined);
  const { realtySnapshotId, realtyId } = useParams();
  const [apiErrors, handleApiError] = useApiErrorHandler();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onSubmit = React.useCallback(
    async (formData: Remms4allForm) => {
      setIsSubmitting(true);

      prepareFormData(formData, realty);

      const response = await authenticatedApiService.realty(realtyId!).snapshots.create_with_report({
        realty_snapshot: { remms4all_form: formData },
      });
      setIsSubmitting(false);
      if (isSuccessful(response)) {
        navigate(ApplicationRoutes.getPath("reportCreated", { reportId: response.data.realty_report_id.toString() }), {
          replace: true,
        });
      } else {
        handleApiError(response);
      }
    },
    [handleApiError, authenticatedApiService, realtyId, navigate, realty],
  );

  const onSave = React.useCallback(
    async (formData: Remms4allForm) => {
      setIsSubmitting(true);

      prepareFormData(formData, realty);
      const response = await authenticatedApiService.realty(realtyId!).snapshots.create({
        realty_snapshot: { remms4all_form: formData },
      });
      setIsSubmitting(false);
      if (isSuccessful(response)) {
        navigate(ApplicationRoutes.getPath("root"), {
          replace: true,
        });
      } else {
        handleApiError(response);
      }
    },
    [handleApiError, authenticatedApiService, realtyId, navigate, realty],
  );

  React.useEffect(() => {
    authenticatedApiService
      .snapshot(realtySnapshotId!)
      .get()
      .then((response) => {
        if (isSuccessful(response)) {
          patchFormData(response.data.remms4all_form);
          if (response.data.remms4all_form["HeizHauptEnerTrKat"]) {
            response.data.remms4all_form["HEIZHAUPTENERTRKAT"] = response.data.remms4all_form["HeizHauptEnerTrKat"];
            response.data.remms4all_form["HeizHauptEnerTrKat"] = undefined;
          }
          if (response.data.remms4all_form.R4A_GUI_DET == null) {
            response.data.remms4all_form.R4A_GUI_DET = RealtyFormType.simple;
          }
          setRealty(response.data.remms4all_form);
        } else {
          handleApiError(response);
        }
      });
    // eslint-disable-next-line
  }, [realtySnapshotId]);

  if (!realty || isEmpty(realty)) return <LoadingScreen />;

  const switchToComplexForm = () => {
    setRealty({ ...realty, R4A_GUI_DET: RealtyFormType.detailed });
  };

  return (
    <FormWrapper<Remms4allForm>
      className="erfassung-full"
      defaultValues={realty}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
    >
      {realty.R4A_GUI_DET === RealtyFormType.detailed ? (
        <_010Erfassung
          onSaveClick={onSave}
          hasSaveButton={true}
          isSubmitting={isSubmitting}
          isLoggedIn={true}
          {...props}
        />
      ) : (
        <_010ErfassungSimpel
          onSwitchToComplexClick={switchToComplexForm}
          isSubmitting={isSubmitting}
          isLoggedIn={true}
          {...props}
        />
      )}
    </FormWrapper>
  );
}

const RealtySnapshotDetail = withAuthenticatedApiService(React.forwardRef(RealtySnapshotDetail_));
export default RealtySnapshotDetail;
